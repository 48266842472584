// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-album-js": () => import("./../../../src/components/album.js" /* webpackChunkName: "component---src-components-album-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-js": () => import("./../../../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-card-2021-js": () => import("./../../../src/pages/card2021.js" /* webpackChunkName: "component---src-pages-card-2021-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

